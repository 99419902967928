import axios from "axios";
import { LOGIN_PAGE } from "../Constants/pages";
import { getItemFromBrowserStorage, isEmptyOrSpaces } from "../utils";
import { BROWSER_STORAGE_KEY_TOKEN } from "../Constants/storage";

export const axiosInstance = axios.create()


axiosInstance.interceptors.request.use(
    async config => {
        const token = getItemFromBrowserStorage(BROWSER_STORAGE_KEY_TOKEN)
        config.headers = {
            'Content-Type': 'multipart/form-data',
            ...config.headers
        }
        if (!isEmptyOrSpaces(token)) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });


axiosInstance.interceptors.response.use((response)=>{
    return response
}, async function (error){
    if (error && error.response && error.response.status == 401) {
        // if you want to display the error message
        let errorMessage = error?.response?.data?.message
        if (window.location.pathname != LOGIN_PAGE) {
            window.location = LOGIN_PAGE
        }
    }
    return Promise.reject(error)
})

export const doPost = (url, payload, headers = {}) => {
    return axiosInstance.post(url, payload, { headers })
}

export const doGet = (url, headers = {}) => {
    return axiosInstance.get(url, { headers })
}