import React, { Component } from 'react';

import { Card, CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getOrderList,
  getDocumentList,
  submitDocuments,
  changePassword
} from '../Pages/AuthPages/api/actions';
import LoadingSpinner from '../../components/Custom/LoadingSpinner';
import Message from '../../components/Custom/Message';
import CustomButton from "../../components/Custom/Button"
class ChangePassword extends Component {
  state = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  }
  componentDidMount() {
    // this.props.getOrderList('loadOrderList');
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.password != this.props.password && this.props.password){
      this.setState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      })
    }
  }

  handleChange = (field, value) => {
    this.setState({
      [field] : value
    })
  }

  handleSubmit = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    let formData = new FormData()
    formData.append("current_password", currentPassword)
    formData.append("new_password", newPassword)
    formData.append("new_password_confirmation", confirmPassword)
    this.props.changePassword(formData, this.props.user.id, "changePassword")
  }

  render() {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    return (
      <React.Fragment>
        <Col lg="8" xs="12">
          {/* <Card className="border-0 rounded shadow">
            <CardBody> */}
          <Message />
          {/* <LoadingSpinner text="Loading" buttonId="loadOrderList" /> */}
          <Card className="border-0 rounded shadow">
            <CardBody>
              <Form>
                <Row className="mt-4">
                  <Col md="6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="currentPassword">
                        Current Password
                      </Label>
                      <Input
                        name="currentPassword"
                        id="currentPassword"
                        type="password"
                        className="form-control"
                        placeholder="Current Password"
                        value={currentPassword}
                        onChange={(e) =>
                          this.handleChange('currentPassword', e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="newPassword">
                        New Password
                      </Label>
                      <Input
                        name="newPassword"
                        id="newPassword"
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) =>
                          this.handleChange('newPassword', e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="confirmPassword">
                        Confirm Password
                      </Label>
                      <Input
                        name="confirmPassword"
                        id="confirmPassword"
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) =>
                          this.handleChange('confirmPassword', e.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <center>
                    <Col sm="12" className="mt-4">
                      <CustomButton
                        color="primary"
                        handleClick={() => {
                          this.handleSubmit();
                        }}
                        // disabled={!isButtonEnabled || !email || !password}
                        text="Submit"
                        buttonId="changePassword"
                      />
                      {/* <CustomButton color="secondary" className="mx-2"
                            handleClick={()=>{this.clearFields()}} 
                            // disabled={!isButtonEnabled || !email || !password}
                            text="Clear"
                            buttonId="clearBtn"/> */}
                    </Col>
                  </center>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderList: state.orderList,
    documentsList: state.documentsList,
    addDocuments: state.addDocuments,
    user: state.authPages.profileData,
    password: state.changePassword
  };
};

const mapDispatchToProps = {
  getOrderList,
  getDocumentList,
  submitDocuments,
  changePassword
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ChangePassword));
