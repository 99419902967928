import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

import Tagline from "./Tagline";

// Scroll up button
import ScrollUpButton from "react-scroll-up-button";

//Import Switcher
import ThemeSwitcher from "./ThemeSwitcher";

//Import Icons
import FeatherIcon from "feather-icons-react";
// import imgbg from "../../../assets/images/account/bg.png";
import imgbg from "../../assets/images/account/bg.png";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Progress,
  Card,
  CardBody,
  Input,
  Label,
  Alert,
  Form  
} from "reactstrap";
import { Link } from "react-router-dom";
import defaultUserImg from "../../assets/images/user/defaultuser.jpg"
import { getProfileDetails } from "../../pages/Pages/AuthPages/api/actions"
// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const NavbarPage = React.lazy(() =>
  import("../../pages/Saas Onepage/NavbarPage")
);
const Footer = React.lazy(() => import("./Footer"));
const FooterWithoutMenuLightSocialOnly = React.lazy(() =>
  import("./FooterWithoutMenuLightSocialOnly")
);

const CustomDot = () => {
  return (
    <React.Fragment>
      <FeatherIcon icon="arrow-up" className="icons" />
    </React.Fragment>
  );
};

const CryptoTagline = () => {
  return <div className="container-fluid">
    <div className="row">
      <div className="col-12">
        <div className="text-slider">
          <ul className="list-inline mb-0 move-text">
            <li className="list-inline-item px-2 mb-0"><b>EOG</b> $ 55.88 <span className="text-success">+$ 4.62 ( +9.01% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>CHKP</b> $ 120.03 <span className="text-danger">-$ 14.07 ( -10.49% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>FANG</b> $ 63.58 <span className="text-success">+$ 5.17 ( +8.84% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>M</b> $ 14.75 <span className="text-success">+$ 1.05 ( +7.66% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>PKI</b> $ 139.72 <span className="text-danger">-$ 11.41 ( -7.55% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>ABMD</b> $ 326.23 <span className="text-danger">-$ 21.61 ( -6.21% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>BITCOIN</b> $ 37,471.47 <span className="text-danger">+$ 492.60 ( +1.33% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>XRP</b> <span> $ 0.39</span><span className="text-muted"> UNCH</span></li>
            <li className="list-inline-item px-2 mb-0"><b>LITECOIN</b> <span> $ 148.67</span><span className="text-danger">-$ 5.58 ( -3.62% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>BITCOIN CASH</b> <span> $ 427.37</span><span className="text-danger">-$ 15.98 ( -3.60% )</span></li>
            <li className="list-inline-item px-2 mb-0"><b>ETHEREUM</b> $ 1,647.87 <span className="text-danger">+$ 14.51 ( +0.89% )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

}

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
class Layout extends Component {

  state = {
    widgets: [
      {
        id: 1,
        icon: "uil uil-dashboard",
        className: "navbar-item account-menu px-0",
        title: "Home",
        link: "/page-profile",
      },
      {
        id: 2,
        icon: "uil uil-users-alt",
        className: "navbar-item account-menu px-0 mt-2",
        title: "Uploads",
        link: "/uploads",
      },
      {
        id: 3,
        icon: "uil uil-file",
        className: "navbar-item account-menu px-0 mt-2",
        title: "Status",
        link: "/status",
      },
      // {
      //   id: 4,
      //   icon: "uil uil-envelope-star",
      //   className: "navbar-item account-menu px-0 mt-2",
      //   title: "Address Book",
      //   link: "/page-messages",
      // },
      {
        id: 5,
        icon: "uil uil-transaction",
        className: "navbar-item account-menu px-0 mt-2",
        title: "Change Password",
        link: "/change-password",
      },
      {
        id: 6,
        icon: "uil uil-transaction",
        className: "navbar-item account-menu px-0 mt-2",
        title: "Edit Profile",
        link: "/edit-profile",
      },
      {
        id: 7,
        icon: "uil uil-setting",
        className: "navbar-item account-menu px-0 mt-2",
        title: "Logout",
        link: "/logout",
      },
      // {
      //   id: 7,
      //   icon: "uil uil-dashboard",
      //   className: "navbar-item account-menu px-0 mt-2",
      //   title: "Logout",
      //   link: "/auth-login-three",
      // },
    ]
  }
  getUserType = () => {
    const { profileData } = this.props
    if(profileData && profileData.user_type_id){
      switch(profileData.user_type_id){
        case 1 : return "Student"
        case 3 : return "Agency"
        default: return "NA"
      }
    }
    return ""
  }

  componentDidMount(){
    if(!this.props.profileData || !Object.keys(this.props.profileData).length){
      this.props.getProfileDetails()
    }
  }

  render() {
    const tagLineContent = this.props.location.pathname === "/index-crypto-two" ? <Tagline><CryptoTagline /></Tagline> : null;
    const { profileData } = this.props
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          {this.props.location.pathname === "/index-onepage" ? (
            <NavbarPage />
          ) : (
            <Topbar tagline={tagLineContent} hasDarkTopBar={this.props.hasDarkTopBar} />
          )}

          <section
            className="bg-profile d-table w-100 bg-primary"
            style={{ background: `url(${imgbg}) center center` }}
          >
            <Container>
              <Row>
                <Col lg="12">
                  <Card
                    className="public-profile border-0 rounded shadow"
                    style={{ zIndex: "1" }}
                  >
                    <CardBody>
                      <Row className="align-items-center">
                        <Col lg="2" md="3" className="text-md-start text-center">
                          <img
                            src={profileData && profileData.profile_picture ? profileData.profile_picture : defaultUserImg}
                            className="avatar avatar-large rounded-circle shadow d-block mx-auto"
                            alt=""
                          />
                        </Col>

                        <Col lg="10" md="9">
                          <Row className="align-items-end">
                            <Col
                              md="7"
                              className="text-md-start text-center mt-4 mt-sm-0"
                            >
                              <h3 className="title mb-0">{profileData && profileData.name ? profileData.name : ""}</h3>
                              <small className="text-muted h6 me-2">
                                {this.getUserType()}
                              </small>
                              <ul className="list-inline mb-0 mt-3">
                                <li className="list-inline-item me-2">
                                  <Link
                                    to="#"
                                    className="text-muted"
                                    title="Linkedin"
                                  >
                                    <i>
                                      <FeatherIcon
                                        icon="mail"
                                        className="fea icon-sm me-2"
                                      />
                                    </i>
                                    {profileData && profileData.email}
                                  </Link>
                                </li>
                                <li className="list-inline-item ms-1">
                                  <Link
                                    to="#"
                                    className="text-muted"
                                    title="Skype"
                                  >
                                    <i>
                                      <FeatherIcon
                                        icon="phone"
                                        className="fea icon-sm me-2"
                                      />
                                    </i>
                                    {profileData && profileData.mobile ? profileData.mobile : ""}
                                  </Link>
                                </li>
                              </ul>
                            </Col>
                            {/* <Col md="5" className="text-md-end text-center">
                              <ul className="list-unstyled social-icon social mb-0 mt-4">
                                <li className="list-inline-item">
                                  <Link to="#" className="rounded">
                                    <i className="uil uil-bell align-middle"></i>
                                  </Link>
                                </li>
                                <li className="list-inline-item">
                                  <Link
                                    to="/page-profile-edit"
                                    className="rounded"
                                  >
                                    <i className="uil uil-cog align-middle"></i>
                                  </Link>
                                </li>
                              </ul>
                            </Col> */}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section mt-60">
            <Container className="mt-lg-3">
              <Row>
                <Col xl="3" lg="4" md="6" xs="12" className="d-lg-block d-none">
                  <div className="sidebar sticky-bar p-4 rounded shadow">
                    <div className="widget mt-4">
                      <ul className="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
                        {this.state.widgets.map((widget, key) => (
                          <li className={widget.className+` ${(widget.link == window.location.pathname) || ( window.location.pathname == '/' && key == 0)?"active":""}`} key={key} onClick={(e)=>{e.preventDefault(); window.scroll(0,0)}}>
                            <Link to={widget.link} className="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
                              <span className="h4 mb-0">
                                <i className={widget.icon}></i>
                              </span>
                              <h6 className="mb-0 ms-2">{widget.title}</h6>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Col>
                {this.props.children}
              </Row>
            </Container>
          </section>
          {(() => {
            if (
              this.props.location.pathname === "/index-marketing" ||
              this.props.location.pathname === "/index-digital-agency" ||
              this.props.location.pathname === "/index-modern-business" ||
              this.props.location.pathname === "/index-services" ||
              this.props.location.pathname === "/index-job" ||
              this.props.location.pathname === "index-freelancer"
            ) {
              return <Footer isLight={true} />;
            } else if (
              this.props.location.pathname === "/index-portfolio"
            ) {
              return (
                <FooterWithoutMenuLightSocialOnly className="border-0 bg-light text-dark" />
              );
            } else if (
              this.props.location.pathname === "/index-personal" ||
              this.props.location.pathname === "/helpcenter-overview" ||
              this.props.location.pathname === '/helpcenter-guides' ||
              this.props.location.pathname === '/helpcenter-support-request' ||
              this.props.location.pathname === "/page-invoice"
            ) {
              return <FooterWithoutMenuLightSocialOnly className="" />;
            } else {
              return <Footer />;
            }
          })()}

          {/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
          {/* scrollup button */}
          <ScrollUpButton
            ContainerClassName="classForContainer"
            style={{ height: 36, width: 36 }}
            TransitionClassName="classForTransition"
          >
            <CustomDot />
          </ScrollUpButton>
          {/* </div> */}

          {/* theme switcher */}
          <ThemeSwitcher />
        </Suspense>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    profileData: state.authPages.profileData
  }
}

const mapDispatchToProps = {
  getProfileDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
