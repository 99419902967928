// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  // Button,
  Card,
  CardBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { _URL_REGISTER } from '../../../Constants/url'

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import signup from "../../../assets/images/user/signup.svg";
// import { withSuspense } from "../../../middleware/suspense";
import { connect } from "react-redux";
import { signUp } from "./api/actions";
import Message from "../../../components/Custom/Message";
import CustomButton from "../../../components/Custom/Button";
import { setMessages, clearMessages } from "../../../middleware/commonActions"
import { getItemFromBrowserStorage, removeItemFromBrowserStorage } from "../../../utils";
import { TOKEN } from "../../../Constants";

class Page404 extends Component {

  constructor(props){
    super(props)
    this.state = {
      data: {
        name: "",
        email: "",
        password: "",
        // password_confirmation: "",
        mobile: "",
        user_type_id: 1
      },
      radioButtons: [{
        id: "student",
        value: 1,
        label: "Student"
      }, {
        id: "agent",
        value: 3,
        label: "Agent"
      }],
      errorResponse: {},
      acceptTerms: false
    }
  }

  componentDidMount() {
    const token = getItemFromBrowserStorage(TOKEN)
    if(token){
      removeItemFromBrowserStorage(TOKEN)
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.signed) != JSON.stringify(this.props.signed)
      && this.props.signed
      && Object.keys(this.props.signed).length) {
      // window.location = `/auth-verify-otp?id=${this.props.signed.user.id}`
      this.props.history.push(`/auth-verify-otp?id=${this.props.signed.user.id}`)
    }
  }

  handleChange = (field, value)=>{
    const { data } = this.state
    data[field] = value
    this.setState({
      data
    })
  }

  handleSubmit = () => {
    this.props.clearMessages()
    const { data } = this.state
    if (Object.keys(data).length) {
      let canProceed = true
      Object.keys(data).map(stateData => {
        if (data[stateData] == "") {
          canProceed = false
        }
      })
      if (canProceed) {
        let formData = new FormData()
        Object.keys(data).map(stateData => {
          formData.append(stateData, data[stateData])
        })

        this.props.signUp(formData, "registerBtn")
      } else {
        this.props.setMessages()
      }
    }
  }
  
  render() {
    const { data, radioButtons, errorResponse, acceptTerms } = this.state
    const { name, email, password, mobile, user_type_id } = data
    return (
      <React.Fragment>
        <div className="back-to-home rounded d-none d-sm-block">
          <Link to="/" className="btn btn-icon btn-soft-primary">
            <i>
              <FeatherIcon icon="home" className="icons" />
            </i>
          </Link>
        </div>
        <section className="bg-auth-home d-table w-100">
          <Container>
            <Row className="align-items-center">
              <Col lg="7" md="6">
                <div className="me-lg-5">
                  <img
                    src={signup}
                    className="img-fluid d-block mx-auto"
                    alt=""
                  />
                </div>
              </Col>
              <Col lg="5" md="6" className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                404 Page Not Found!
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Page404;
