import { BROWSER_STORAGE_TYPE_LOCAL, BROWSER_STORAGE_TYPE_SESSION } from "../Constants/storage"

export const getItemFromBrowserStorage = (itemKey, storageType = BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        return window.sessionStorage.getItem(itemKey)
    }
    return window.localStorage.getItem(itemKey)
}

export const setItemToBrowserStorage = (itemKey, value, storageType = BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        window.sessionStorage.setItem(itemKey, value)
    } else if(storageType == BROWSER_STORAGE_TYPE_LOCAL){
        window.localStorage.setItem(itemKey, value)
    }
}

export const removeItemFromBrowserStorage = (itemKey, storageType = BROWSER_STORAGE_TYPE_LOCAL) => {
    if(storageType == BROWSER_STORAGE_TYPE_SESSION){
        window.sessionStorage.removeItem(itemKey)
    } else if(storageType == BROWSER_STORAGE_TYPE_LOCAL){
        window.localStorage.removeItem(itemKey)
    }
}

export const isEmptyOrSpaces = (str) => {
    return str === null || str === "null" || str === undefined || str === "undefined" || str.match(/^ *$/) !== null
}