import { ADD_DOCUMENT_LIST, CHANGE_PASSWORD, CREATE_PAYMENT, CREATE_SERVICE, DOCUMENT_LIST, LOCATION_LIST, LOGIN_SUCCESS, LOGOUT, ORDER_LIST, ORDER_STATUS, PROFILE_DATA, PROFILE_UPDATE, SIGNUP_DATA, SIGNUP_SUCCESS, UNIVERSITY_LIST, UNIVERSITY_DETAILS, COUNTRIES_LIST, UNIVERSITY_SERVICE_LIST, VERIFY_OTP_SUCCESS, VERIFY_PAYMENT, CREATE_ADDITIONAL_PAYMENT } from "./constants"

const initialState = {
    signUp: {},
    verifyOtp: {},
    signUpData: {},
    loggedIn: {},
    profileData: {},
    logOut:""
}

export const authPagesReducer = (state, action) => {
    const { type, payload } = action
    if(!state) state = initialState
    if(type === SIGNUP_SUCCESS){
        return {
            signUp : payload
        }
    }
    if(type === VERIFY_OTP_SUCCESS){
        return {
            verifyOtp: payload
        }
    }
    if(type === SIGNUP_DATA){
        return {
            signUpData: payload
        }
    }
    if(type === LOGIN_SUCCESS){
        return {
            loggedIn: payload
        }
    }
    if(type === PROFILE_DATA){
        return {
            profileData: payload
        }
    }
    return state
}

export const universityReducer = (state, action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == UNIVERSITY_LIST){
        return payload
    }
    return state
}

export const universityDetailsReducer = (state, action) => {
    const { type, payload } = action
    if(!state) state = null
    if(type == UNIVERSITY_DETAILS){
        return payload
    }
    return state
}

export const countriesListReducer = (state, action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == COUNTRIES_LIST){
        return payload
    }
    return state
}

export const universityServiceReducer = (state, action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == UNIVERSITY_SERVICE_LIST){
        return payload
    }
    return state
}

export const locationsReducer = (state=[], action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == LOCATION_LIST){
        return payload
    }
    return state
}

export const createServiceReducer = (state={}, action) => {
    const { type, payload } = action
    if(!state) state = {}
    if(type == CREATE_SERVICE){
        return payload
    }
    return state
}

const intialPayment = { create: {}, verify: {}}
export const paymentReducer = (state=intialPayment, action) => {
    const { type, payload } = action
    if(!state) state = intialPayment
    if(type == CREATE_PAYMENT){
        return {... {create: payload}}
    }
    if(type == VERIFY_PAYMENT){
        return {... {verify: payload}}
    }
    return state
}

const intialAdditionalPayment = { create: {}, verify: {}}
export const additionalPaymentReducer = (state=intialAdditionalPayment, action) => {
    const { type, payload } = action
    if(!state) state = intialAdditionalPayment
    if(type == CREATE_ADDITIONAL_PAYMENT){
        return {... {create: payload}}
    }
    if(type == VERIFY_PAYMENT){
        return {... {verify: payload}}
    }
    return state
}

export const orderListReducer = (state=[], action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == ORDER_LIST){
        return payload
    }
    return state
}

export const documentsListReducer = (state=[], action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == DOCUMENT_LIST){
        return payload
    }
    return state
}

export const addDocumentsReducer = (state=[], action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == ADD_DOCUMENT_LIST){
        return payload
    }
    return state
}

export const orderStatusReducer = (state=[], action) => {
    const { type, payload } = action
    if(!state) state = []
    if(type == ORDER_STATUS){
        return payload
    }
    return state
}

export const passwordReducer = (state="", action) => {
    const { type, payload } = action
    if(!state) state = ""
    if(type == CHANGE_PASSWORD){
        return payload
    }
    return state
}

export const logOutReducer = (state="", action) => {
    const { type, payload } = action
    if(!state) state = ""
    if(type == LOGOUT){
        return payload
    }
    return state
}

export const updateProfileReducer = (state="", action) => {
    const { type, payload } = action
    if(!state) state = ""
    if(type == PROFILE_UPDATE){
        return payload
    }
    return state
}