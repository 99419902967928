import { LOADING } from "../Constants/actions"

const intialState = {
    buttonId: "",
    value: false
}
export const loadingReducer = (state, action) => {
    const { type, payload } = action
    if (!state) state = intialState
    if (type === LOADING) {
        return {...payload}
    }
    return state
}