export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const USERS = "users"
export const AUTH_LOGIN = "auth/login"
export const AUTH = "auth"
export const AUTH_ME = "auth/me"
export const AUTH_LOGOUT = "auth/logout"
export const PARAM_USER_ID = "/{USER_ID}/"
export const VERIFY_EMAIL_OTP = "verify-email-otp"
export const UNIVERSITIES = "universities/"
export const PARAM_UNIVERSITY_ID = "{UNIVERSITY_ID}"
export const LOCATIONS = "locations/"
export const LCOATION_ID = "{LOCATION_ID}"
export const SERVICE_ORDER_ID = "/{SERVICE_ORDER_ID}/"
export const SERVICE_ORDERS = "service-orders"
export const PAYMENT_CREATE_ORDER = "payment-create-order"
export const PAYMENT_CREATE_ADDITIONAL_ORDER = "additional-amount-create-order"
export const PAYMENT_VERIFY_ORDER = "payment-verify-order"
export const SERVICES = "services"
export const ADD_DOCUMENTS = "/add-documents"
export const SERVICE_ID = "/{SERVICE_ID}"
export const ORDER_ITEMS = "/order-items"
export const ORDER_TRACKING = "order-tracking"
export const CHANGE_PASSWORD = "change-password"
export const FORGOT_PASSWORD = "/forgot-password"
export const DETAILS = "/details"
export const COUNTRIES = "delivery-price"


export const _URL_REGISTER = BASE_URL+ USERS
export const _URL_LOGIN = BASE_URL+ AUTH_LOGIN
export const _URL_VERIFY_OTP = BASE_URL + USERS + PARAM_USER_ID + VERIFY_EMAIL_OTP
export const _URL_GET_PROFILE = BASE_URL + AUTH_ME
export const _URL_GET_UNIVERSITY_LIST = BASE_URL + LOCATIONS + LCOATION_ID
export const _URL_GET_UNIVERSITY_SERVICE_LIST = BASE_URL + UNIVERSITIES + PARAM_UNIVERSITY_ID
export const _URL_GET_UNIVERSITY_DETAILS_LIST = BASE_URL + UNIVERSITIES + PARAM_UNIVERSITY_ID + DETAILS
export const _URL_GET_COUNTRIES_LIST = BASE_URL + COUNTRIES 
export const _URL_GET_LOCATIONS = BASE_URL + LOCATIONS
export const _URL_CREATE_SERVICE = BASE_URL + SERVICE_ORDERS
export const _URL_CREATE_PAYMENT = BASE_URL + SERVICE_ORDERS + SERVICE_ORDER_ID + PAYMENT_CREATE_ORDER
export const _URL_CREATE_ADDITIONAL_PAYMENT = BASE_URL + SERVICE_ORDERS + SERVICE_ORDER_ID + PAYMENT_CREATE_ADDITIONAL_ORDER
export const _URL_VERIFY_PAYMENT = BASE_URL + SERVICE_ORDERS + SERVICE_ORDER_ID + PAYMENT_VERIFY_ORDER
export const _URL_GET_SERVICE_DOCUMENTS = BASE_URL + SERVICES + SERVICE_ID
export const _URL_GET_UPLOADED_SERVICE_DOCUMENTS = BASE_URL + SERVICE_ORDERS + SERVICE_ID + ORDER_ITEMS 
export const _URL_ADD_SERVICE_DOCUMENTS = BASE_URL + SERVICE_ORDERS + SERVICE_ID + ADD_DOCUMENTS
export const _URL_GET_ORDER_STATUS = BASE_URL + SERVICE_ORDERS + SERVICE_ORDER_ID + ORDER_TRACKING
export const _URL_CHANGE_PASSWORD = BASE_URL + USERS + PARAM_USER_ID + CHANGE_PASSWORD
export const _URL_LOGOUT = BASE_URL + AUTH_LOGOUT
export const _URL_UPDATE_PROFILE = BASE_URL + USERS + PARAM_USER_ID
export const _URL_RESET_PASSWORD = BASE_URL + AUTH + FORGOT_PASSWORD