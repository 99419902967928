import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Spinner } from 'reactstrap';

class CustomButton extends Component {
  render() {
    const { color, handleClick, disabled, text , loadingId, loadingValue, buttonId, className} = this.props;
    const loadSpinner = (buttonId==loadingId) && loadingValue
    return (
      <React.Fragment>
        <Button
          color={color}
          onClick={() => handleClick()}
          disabled={disabled || loadSpinner}
          className={className}
        >
          {loadSpinner && <Spinner children={false} size="sm" />} {text }
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
    return {
     loadingValue: state.loader.value,
     loadingId: state.loader.buttonId
    }
  }
  
  const mapDispatchToProps = {
    // signUp, setMessages, clearMessages
  }
export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
