import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.messages) !=
        JSON.stringify(this.props.messages) &&
      this.props.messages &&
      this.props.messages.length
    ) {
      this.setState({
        isOpen: true,
      });
    }
  }

  renderMessages = () => {
    const { messages, type } = this.props;
    if (messages && messages.length) {
      if (messages.length == 1)
        return (
          <Alert
            color={type}
            isOpen={this.state.isOpen}
            toggle={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            {messages}
          </Alert>
        );
      else {
        return (
          <Alert
            color={type}
            isOpen={this.state.isOpen}
            toggle={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <ul>
              {messages.map((msg, idx) => {
                return <li key={idx}>{msg}</li>;
              })}
            </ul>
          </Alert>
        );
      }
    } else {
      return <div></div>;
    }
  };

  render() {
    return this.renderMessages();
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages.content,
    type: state.messages.type,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Message);
