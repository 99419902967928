import { MESSAGE, RESET_MESSAGE } from "../Constants/actions"

const intialState = {
    content: [],
    type: ""
}
export const messageReducer = (state, action) => {
    const { type, payload } = action
    if (!state) state = intialState
    if (type === MESSAGE) {
        return {...payload}
    }
    if(type == RESET_MESSAGE){
        return intialState
    }
    return state
}