export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const SIGNUP_DATA = "SIGNUP_DATA"
export const PROFILE_DATA = "PROFILE_DATA"
export const UNIVERSITY_LIST = "UNIVERSITY_LIST"
export const UNIVERSITY_DETAILS = "UNIVERSITY_DETAILS"
export const UNIVERSITY_SERVICE_LIST = "UNIVERSITY_SERVICE_LIST"
export const LOCATION_LIST = "LOCATION_LIST"
export const CREATE_SERVICE = "CREATE_SERVICE"
export const CREATE_PAYMENT = "CREATE_PAYMENT"
export const CREATE_ADDITIONAL_PAYMENT = "CREATE_ADDITIONAL_PAYMENT"
export const VERIFY_PAYMENT = "VERIFY_PAYMENT"
export const ORDER_LIST = "ORDER_LIST"
export const DOCUMENT_LIST = "DOCUMENT_LIST"
export const ORDER_STATUS = "ORDER_STATUS"
export const ADD_DOCUMENT_LIST = "ADD_DOCUMENT_LIST"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const LOGOUT = "LOGOUT"
export const PROFILE_UPDATE = "PROFILE_UPDATE"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const COUNTRIES_LIST = 'COUNTRIES_LIST';