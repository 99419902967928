import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';

class LoadingSpinner extends Component {
  render() {
    const { loadingId, loadingValue, buttonId, text } = this.props;
    const loadSpinner = buttonId == loadingId && loadingValue;
    return (
      <React.Fragment>
        {loadSpinner && <Spinner children={false} size="sm" />}
        {loadSpinner && text && text != '' ? text : ''}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingValue: state.loader.value,
    loadingId: state.loader.buttonId,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(LoadingSpinner);
