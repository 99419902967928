import { doGet, doPost } from "../../../../middleware/api"
import { ADD_DOCUMENTS, PARAM_UNIVERSITY_ID, SERVICE_ORDER_ID, _URL_ADD_SERVICE_DOCUMENTS, _URL_CHANGE_PASSWORD, _URL_CREATE_ADDITIONAL_PAYMENT, _URL_CREATE_PAYMENT, _URL_CREATE_SERVICE, _URL_GET_COUNTRIES_LIST, _URL_GET_LOCATIONS, _URL_GET_ORDER_STATUS, _URL_GET_PROFILE, _URL_GET_SERVICE_DOCUMENTS, _URL_GET_UNIVERSITY_DETAILS_LIST, _URL_GET_UNIVERSITY_LIST, _URL_GET_UNIVERSITY_SERVICE_LIST, _URL_GET_UPLOADED_SERVICE_DOCUMENTS, _URL_LOGIN, _URL_LOGOUT, _URL_REGISTER, _URL_RESET_PASSWORD, _URL_UPDATE_PROFILE, _URL_VERIFY_PAYMENT } from '../../../../Constants/url'
import { ADD_DOCUMENT_LIST, CREATE_PAYMENT, CREATE_SERVICE, DOCUMENT_LIST, LOCATION_LIST, LOGIN_SUCCESS, ORDER_LIST, ORDER_STATUS, PROFILE_DATA, SIGNUP_DATA, SIGNUP_SUCCESS, UNIVERSITY_LIST, UNIVERSITY_SERVICE_LIST, VERIFY_OTP_SUCCESS, VERIFY_PAYMENT, CHANGE_PASSWORD, LOGOUT, PROFILE_UPDATE, RESET_PASSWORD, UNIVERSITY_DETAILS, COUNTRIES_LIST, CREATE_ADDITIONAL_PAYMENT } from "./constants"
import { LOADING, MESSAGE, RESET_MESSAGE, SUCCESS } from "../../../../Constants/actions"
import { getApiErrorMessage } from "../../../../utils/api"
import { setMessages } from "../../../../middleware/commonActions"
import { EMAIL_OR_MOBILE } from './../../../../Constants/index';

export const signUp = (payload, buttonId) => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        dispatch({
            type: SIGNUP_DATA,
            payload: payload
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        return doPost(_URL_REGISTER, payload)
            .then(response => {
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                dispatch({
                    type: SIGNUP_SUCCESS,
                    payload: response.data
                })
            }).catch(error => {
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                dispatch({
                    type: MESSAGE,
                    payload: {
                        content: getApiErrorMessage(error),
                        type: "danger"
                    }
                })
            })
    }
}

export const login = (payload, buttonId) => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        const headers = {
            'x-app-type': 'user'
        }
        return doPost(_URL_LOGIN, payload, headers).then(response => {
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            })
        }).catch(error => {
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: getApiErrorMessage(error),
                    type: "danger"
                }
            })
        })
    }
}

export const verifyOtp = (url, payload, buttonId) => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        return doPost(url, payload).then(response=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: VERIFY_OTP_SUCCESS,
                payload: response.data
            })
          }).catch(error=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                  type: MESSAGE,
                  payload: {
                      content: getApiErrorMessage(error),
                      type: "danger"
                  }
              })
          })
    }
}

export const getProfileDetails = () => {
    return dispatch=>{
        return doGet(_URL_GET_PROFILE).then(response=>{
            dispatch({
                type: PROFILE_DATA,
                payload: response.data
            })
        }).catch(error=>{
            console.log("Error: ", error.response);
        })
    }
}

export const getCountriesList = () => {
    return dispatch=>{
        return doGet(_URL_GET_COUNTRIES_LIST).then(response=>{
            dispatch({
                type: COUNTRIES_LIST,
                payload: response.data
            })
        }).catch(error=>{
            console.log("Error: ", error.response);
        })
    }
}

export const getUniversityList = (id, buttonId) => {
    return dispatch => {
        dispatch({
            type: UNIVERSITY_SERVICE_LIST,
            payload: []
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        const url = _URL_GET_UNIVERSITY_LIST.replace("{LOCATION_ID}", id)
        return doGet(url).then(response=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: UNIVERSITY_LIST,
                payload: response.data
            })
        }).catch(err=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: true
                }
            })
            console.log("Error: ", err.response);
        })
    }
}

export const getLocationsList = (buttonId) => {
    return dispatch => {
        dispatch({
            type: LOCATION_LIST,
            payload: []
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        return doGet(_URL_GET_LOCATIONS).then(response=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: LOCATION_LIST,
                payload: response.data
            })
        }).catch(err=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            console.log("Error: ", err.response);
        })
    }
}

export const getUniversityServiceList = (universityId, buttonId) => {
    return dispatch => {
        const GET_URL = _URL_GET_UNIVERSITY_SERVICE_LIST.replace(PARAM_UNIVERSITY_ID, universityId)
        dispatch({
            type: UNIVERSITY_SERVICE_LIST,
            payload: []
        })
        if(universityId){
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: true
                }
            })
            return doGet(GET_URL).then(response=>{
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                dispatch({
                    type: UNIVERSITY_SERVICE_LIST,
                    payload: response.data
                })
            }).catch(err=>{
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                console.log("Error: ", err.response);
            })
        }
    }
}

export const getUniversityDetailsList = (universityId, buttonId) => {
    return dispatch => {
        const GET_URL =  _URL_GET_UNIVERSITY_DETAILS_LIST.replace(PARAM_UNIVERSITY_ID, universityId)
        dispatch({
            type: UNIVERSITY_DETAILS,
            payload: null 
        })
        if(universityId){
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: true
                }
            })
            return doGet(GET_URL).then(response=>{
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                dispatch({
                    type: UNIVERSITY_DETAILS,
                    payload: response.data
                })
            }).catch(err=>{
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                console.log("Error: ", err.response);
            })
        }
    }
}


export const createService = (payload, buttonId) => {
    return dispatch => {
        dispatch({
            type: CREATE_SERVICE,
            payload: {}
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        dispatch(setMessages([]))
        doPost(_URL_CREATE_SERVICE, payload).then(response=>{
            dispatch({
                type: CREATE_SERVICE,
                payload: response.data
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            if(response.data.message && response.data.message != "service order created"){
                window.scroll(0,0)
                dispatch(setMessages([response.data.message], "danger"))
            }
            
            // after doPost scrollup to show pricing
            window.scroll(0,330)
        }).catch(error=>{
            window.scroll(0,0)
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            if(error.response && error.response.data && error.response.data.errors){
                const { errors } = error.response.data
                let errorArray = []
                Object.keys(errors).map(err=>{
                    if(errors[err].length){
                        errors[err].map(arr=>{
                            errorArray.push(arr)
                        })
                    }
                })
                dispatch(setMessages(errorArray, "danger"))
            }
        })
    }
}

export const createPayment = (id,  buttonId) => {
    return dispatch => {
        dispatch({
            type: CREATE_PAYMENT,
            payload: {}
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        dispatch(setMessages([]))
        const url = _URL_CREATE_PAYMENT.replace("{SERVICE_ORDER_ID}", id )
        doPost(url).then(response=>{
            dispatch({
                type: CREATE_PAYMENT,
                payload: response.data
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            window.scroll(0,0)
        }).catch(error=>{
            console.log("Error: ", error.response);
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
        })
    }
}

export const createAdditionalPayment = (id,  buttonId) => {
    return dispatch => {
        dispatch({
            type: CREATE_ADDITIONAL_PAYMENT,
            payload: {}
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        dispatch(setMessages([]))
        const url = _URL_CREATE_ADDITIONAL_PAYMENT.replace("{SERVICE_ORDER_ID}", id )
        doPost(url).then(response=>{
            dispatch({
                type: CREATE_ADDITIONAL_PAYMENT,
                payload: response.data
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            window.scroll(0,0)
        }).catch(error=>{
            console.log("Error: ", error.response);
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
        })
    }
}

export const verifyPayment = (payload, serviceOrderId) => {
    const url = _URL_VERIFY_PAYMENT.replace("{SERVICE_ORDER_ID}", serviceOrderId)
    return dispatch=>{
        dispatch(setMessages([]))
        dispatch({
            type: VERIFY_PAYMENT,
            payload: {}
        })
        doPost(url, payload).then(response=>{
            dispatch(setMessages([response.data.message], "success"))
            dispatch({
                type: VERIFY_PAYMENT,
                payload: response.data
            })
        }).catch(err=>{
            console.log("Error: ", err);
            dispatch(setMessages(["Payment Failed"]))
        })
    }
}

export const getOrderList = (buttonId) => {
    return dispatch => {
        dispatch({
            type: ORDER_LIST,
            payload: []
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        doGet(_URL_CREATE_SERVICE).then(response=>{
            dispatch({
                type: ORDER_LIST,
                payload: response.data.data
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
        }).catch(error=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const getDocumentList = (serviceId, buttonId) => {
    
    return dispatch => {
        dispatch({
            type: DOCUMENT_LIST,
            payload: []
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        const url = _URL_GET_SERVICE_DOCUMENTS.replace("{SERVICE_ID}", serviceId)
        doGet(url).then(response=>{
            dispatch({
                type: DOCUMENT_LIST,
                payload: response.data
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
        }).catch(error=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const getRejectedDocumentList = (orderId, serviceId, buttonId) => {

    return dispatch => {
        dispatch({
            type: DOCUMENT_LIST,
            payload: []
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })

        const url = _URL_GET_SERVICE_DOCUMENTS.replace("{SERVICE_ID}", serviceId)
        const uploadedDocsUrl = _URL_GET_UPLOADED_SERVICE_DOCUMENTS.replace("{SERVICE_ID}", orderId)

        doGet(url).then(response=>{
            doGet(uploadedDocsUrl).then(docs => {
                const allDocs = response.data;
                const uploadedDocs = docs.data;

                const rejectedDocs = allDocs.filter(doc => {
                    return uploadedDocs.every(uploadedDoc => {
                        return uploadedDoc.documents.id !== doc.id;
                    });
                });

                dispatch({
                    type: DOCUMENT_LIST,
                    payload: rejectedDocs
                })
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })

            }).catch(uploadedDocsError => {
                dispatch({
                    type: LOADING,
                    payload: {
                        buttonId,
                        value: false
                    }
                })
                console.log("Error: ", uploadedDocsError);
            });
        }).catch(error=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const submitDocuments = (payload, serviceId, buttonId) => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        dispatch({
            type: ADD_DOCUMENT_LIST,
            payload: {}
        })
        const url = _URL_ADD_SERVICE_DOCUMENTS.replace("{SERVICE_ID}", serviceId)
        doPost(url, payload).then(response=>{
            dispatch({
                type: MESSAGE,
                payload: {
                    content: [response.data.message],
                    type: "success"
                }
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: ADD_DOCUMENT_LIST,
                payload: response.data
            })
        }).catch(error=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: getApiErrorMessage(error),
                    type: "danger"
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const getOrderStatus = (serviceOrderId, buttonId) => {
    return dispatch => {
        dispatch({
            type: ORDER_STATUS,
            payload: {}
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        const url = _URL_GET_ORDER_STATUS.replace("{SERVICE_ORDER_ID}", serviceOrderId)
        doGet(url).then(response=>{
            dispatch({
                type: ORDER_STATUS,
                payload: response.data
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
        }).catch(error=>{
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const changePassword = (payload, userId, buttonId) => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value: true
            }
        })
        dispatch({
            type: CHANGE_PASSWORD,
            payload: ""
        })
        const url = _URL_CHANGE_PASSWORD.replace("{USER_ID}", userId)
        doPost(url, payload).then(response => {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: response.data.message
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: [response.data.message],
                    type: "success"
                }
            })
        }).catch(error => {
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: getApiErrorMessage(error),
                    type: "danger"
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const logOutUser = () => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
       
        // dispatch({
        //     type: LOADING,
        //     payload: {
        //         buttonId,
        //         value: true
        //     }
        // })
        dispatch({
            type: LOGOUT,
            payload: ""
        })
        doPost(_URL_LOGOUT).then(response => {
            dispatch({
                type: LOGOUT,
                payload: response.data.message
            })
            // dispatch({
            //     type: LOADING,
            //     payload: {
            //         buttonId,
            //         value: false
            //     }
            // })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: [response.data.message],
                    type: "success"
                }
            })
        }).catch(error => {
            // dispatch({
            //     type: LOADING,
            //     payload: {
            //         buttonId,
            //         value: false
            //     }
            // })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: getApiErrorMessage(error),
                    type: "danger"
                }
            })
            console.log("Error: ", error);
        }).finally(() => {
            localStorage.clear();
        })
    }
}

export const updateProfileData = (payload, user_id, buttonId) => {
    
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        dispatch({
            type: PROFILE_UPDATE,
            payload: ""
        })
        const url = _URL_UPDATE_PROFILE.replace("{USER_ID}", user_id)
        doPost(url, payload).then(response => {
            dispatch({
                type: PROFILE_UPDATE,
                payload: response.data.message
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: [response.data.message],
                    type: "success"
                }
            })
        }).catch(error => {
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: getApiErrorMessage(error),
                    type: "danger"
                }
            })
            console.log("Error: ", error);
        })
    }
}

export const resetPassword = (payload, buttonId) => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
        doPost(_URL_RESET_PASSWORD, payload, {"accept": "application/json"}).then(response => {
            dispatch({
                type: RESET_PASSWORD,
                payload: response.data.message
            })
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: [response.data.message],
                    type: "success"
                }
            })
        }).catch(error => {
            dispatch({
                type: LOADING,
                payload: {
                    buttonId,
                    value: false
                }
            })
            dispatch({
                type: MESSAGE,
                payload: {
                    content: getApiErrorMessage(error),
                    type: "danger"
                }
            })
            console.log("Error: ", error);
        })
    }
}