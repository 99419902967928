import { DANGER, LOADING, MESSAGE, RESET_MESSAGE } from "../Constants/actions"

export const clearMessages =  () => {
    return dispatch => {
        dispatch({
            type: RESET_MESSAGE
        })
    }
}

export const setMessages =  (content=["Please fill the required fields"], type=DANGER) => {
    return dispatch => {
        dispatch({
            type: MESSAGE,
            payload: {
                content,
                type: type
            }
        })
    }
}

export const setLoading = (buttonId, value) => {
    return dispatch => {
        dispatch({
            type: LOADING,
            payload: {
                buttonId,
                value
            }
        })
    }
}