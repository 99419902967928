import { combineReducers } from "redux";
import { addDocumentsReducer,logOutReducer,  passwordReducer, authPagesReducer, createServiceReducer, documentsListReducer, locationsReducer, orderListReducer, orderStatusReducer, paymentReducer, universityReducer, universityDetailsReducer, universityServiceReducer, updateProfileReducer, countriesListReducer, additionalPaymentReducer } from '../pages/Pages/AuthPages/api/reducer'
import { loadingReducer } from "../utils/loadingReducer";
import { messageReducer } from "../utils/messageReducer";

const appReducer = combineReducers({
    authPages: authPagesReducer,
    messages: messageReducer,
    loader: loadingReducer,
    universities: universityReducer,
    universitiesDetails: universityDetailsReducer,
    universityServices: universityServiceReducer,
    locations: locationsReducer,
    createService: createServiceReducer,
    payment: paymentReducer,
    additionalPayment: additionalPaymentReducer,
    orderList: orderListReducer,
    documentsList: documentsListReducer,
    countriesList: countriesListReducer,
    addDocuments: addDocumentsReducer,
    orderStatus: orderStatusReducer,
    changePassword: passwordReducer,
    logOutReducer,
    editProfile: updateProfileReducer
})

const rootReducer = (state, action) => {
    // Clear all data in redux store to initial
    if(action.type == "RESET_STATE"){
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer