export const getApiErrorMessage = (apiError) => {
    let errorResponse = []
    if (apiError && apiError.response && apiError.response.data) {
        const { error, errors, exception, message } = apiError.response.data
        if(errors){
            Object.keys(errors).map(errorData => {
                if(errors[errorData] && errors[errorData].length>0){
                    errors[errorData].map(err=>{
                        errorResponse.push(err)
                    })
                }
            })
        }
        if(error){
            errorResponse.push(error)
        }
        if(exception){
            errorResponse.push(exception)
        }
        if(message){
            errorResponse.push(message)
        }
    }
    return errorResponse
}

export const loadScript = (src) => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = src
      document.body.appendChild(script)
      script.onload = ()=>{
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
    })
  }